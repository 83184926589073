import React, { useEffect, useState } from "react"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"

import BillingInformation from "../components/checkout/billingInformation"
import CheckoutNav from "../components/checkout/checkoutNav"
import { Elements } from "@stripe/react-stripe-js"
import EventInfo from "../components/checkout/eventInfo"
import InvoiceGenerated from "../components/checkout/invoiceGenerated"
import Layout from "../components/layout/layout"
import Loading from "../components/loading"
import OrderConfirmation from "../components/checkout/orderConfirmation"
import PaymentDetails from "../components/checkout/paymentDetails"
import Redirecting from "../components/redirecting"
import ShippingInformation from "../components/checkout/shippingInformation"
import StepWizard from "react-step-wizard"
import TicketHolderInformation from "../components/checkout/ticketHolderInformation"
import axios from "axios"
import { loadStripe } from "@stripe/stripe-js"
import { navigate } from "gatsby-link"
import queryString from "query-string"

const PageComponent = ({ location }) => {
	const { getIdTokenClaims, user } = useAuth0()
	const qs = queryString.parse(location.search)

	const [stepWizard, setStepWizard] = useState()
	const [order, setOrder] = useState()
	const [invoiceId, setInvoiceId] = useState()
	const [stripeInfo, setStripeInfo] = useState()
	const [token, setToken] = useState()
	const [loaded, setLoaded] = useState(false)
	const stripePromise = loadStripe(process.env.GATSBY_STRIPE_API_KEY)
	const [mainContactRef, setMainContactRef] = useState()
	const [contactSet, setContactSet] = useState(false)
	const [membership, setMembership] = useState(false)
	const [loadedMembership, setLoadedMembership] = useState(false)
	const [createdInvoicedId, setCreatedInvoicedId] = useState()
	const [cardError, setCardError] = useState(null)

	const getOrder = async () => {
		try {
			const tokenClaims = await getIdTokenClaims()
			const { data } = await axios.get(
				`${process.env.GATSBY_API_BASE_URL}/order/${qs.orderId}`,
				{
					headers: {
						Authorization: `Bearer ${tokenClaims.__raw}`,
					},
				}
			)

			if (data.state === 4) {
				//already invoiced
				// window.alert("already invoiced")
				navigate("/")
			}

			if (!contactSet) {
				if (data.orderDetails.length > 1) {
					setMainContactRef(
						data.orderDetails[data.orderDetails.length - 1].orderDetailId
					)
				} else {
					setMainContactRef(data.orderDetails[0].orderDetailId)
				}
				setContactSet(true)
			}
			// console.log(data)
			//Todo: Refactor this check
			//Checks if member is in the productName
			if (data.orderDetails[0].productName.includes("Membership")) {
				setMembership(true)
				setLoadedMembership(true)
			}

			// If we already have a membership - Don't allow purchasing another one
			if (user && user["https://ipweansw.org/isMember"] && membership) {
				navigate("/")
			}

			setOrder(data)
		} catch (e) {
			// console.log(e.message)
		}
	}

	const checkTickets = async () => {
		const tokenClaims = await getIdTokenClaims()

		try {
			const response = await axios.post(
				`${process.env.GATSBY_API_BASE_URL}/order/EventTicketInfo`,
				{ orderId: qs.orderId },
				{
					headers: {
						Authorization: `Bearer ${tokenClaims.__raw}`,
					},
				}
			)

			if (response.status) {
				const { data } = response

				// TODO: If an event is sold out what should we do?

				return data.isSoldOut
			}
		} catch {}
	}

	useEffect(() => {
		const orderId = qs.orderId

		try {
			checkTickets()
		} catch {}

		if (orderId) {
			getOrder()
		}
	}, [])

	if (!order) {
		return (
			<Layout>
				<div className="text-center flex h-screen justify-center items-center mb-15">
					<Loading />
				</div>
			</Layout>
		)
	}

	if (invoiceId) {
		return (
			<Layout ClassNames={"padding-overwrite"}>
				<InvoiceGenerated invoiceId={invoiceId} />
			</Layout>
		)
	}

	return (
		<Layout ClassNames={"padding-overwrite"}>
			<Elements stripe={stripePromise}>
				<CheckoutNav
					stepWizardState={stepWizard?.state}
					isDisabled={membership}
				/>
				<EventInfo name={order.productName} />
				<StepWizard
					isHashEnabled={true}
					isLazyMount={true}
					instance={setStepWizard}
				>
					<TicketHolderInformation
						stepName="Ticket Holder Information"
						hashKey="TicketHolderInformation"
						order={order}
						mainContactRef={mainContactRef}
						orderDetailsChanged={getOrder}
						membership={membership}
					/>
					<BillingInformation
						stepName="Billing Information"
						hashKey="BillingInformation"
						order={order}
						orderDetailsChanged={getOrder}
					/>
					{!membership && (
						<ShippingInformation
							stepName={"Shipping Information"}
							hashKey={"ShippingInformation"}
							order={order}
							orderDetailsChanged={getOrder}
						/>
					)}
					<PaymentDetails
						stepName="Payment Details"
						hashKey="PaymentDetails"
						order={order}
						orderDetailsChanged={getOrder}
						setStripeInfo={setStripeInfo}
						setToken={setToken}
						token={token}
						loaded={loaded}
						setLoaded={setLoaded}
						cardError={cardError}
						setCardError={setCardError}
					/>
					<OrderConfirmation
						stepName="Confirm"
						hashKey="Confirm"
						order={order}
						stripeInfo={stripeInfo}
						token={token}
						setToken={setToken}
						loaded={loaded}
						setLoaded={setLoaded}
						membership={membership}
						cardError={cardError}
						setCardError={setCardError}
						invoiceGenerated={id => setInvoiceId(id)}
						invoicedId={createdInvoicedId}
						setInvoicedId={id => setCreatedInvoicedId(id)}
					/>
				</StepWizard>
			</Elements>
		</Layout>
	)
}

export default withAuthenticationRequired(PageComponent, {
	onRedirecting: () => <Redirecting />,
})
